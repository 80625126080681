import React from "react"
import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"
import { Link } from "gatsby"
import { Button, Col, Row } from "react-bootstrap"

const SlideShow = ({ data }) => {
  const phoneNum = process.env.GATSBY_PHONE_NUMBER
  return (
    <Row>
      <Col className="px-0 col-12">
        <Slider className="slider-wrapper" touchDisabled={true} autoplay={3000}>
          {data.map((item, index) => (
            <div
              key={index}
              className="slider-content"
              style={{
                background: `url('${item.image}') no-repeat center center`,
              }}
            >
              <div className="inner">
                <h2>{item.title}</h2>
                <h3>{item.description}</h3>
                {item.button && !item.phone ? (
                  <Link to={item.page}>
                    <Button className="slider-btn">{item.button}</Button>
                  </Link>
                ) : (
                  <a href={`tel: ${phoneNum}`}>
                    <Button className="slider-btn">{item.button}</Button>
                  </a>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </Col>
    </Row>
  )
}

export default SlideShow
