// import PropTypes from "prop-types"
import React from "react"
import { Col, Row } from "react-bootstrap"

const Header = ({ siteTitle, logo }) => {
  return (
    <Row className="header">
      <Col className="col-6 d-flex justify-content-center align-items-center">
        <span>
          <h1 className="header-text text-center mb-0">Driving Secure</h1>
        </span>
      </Col>
      <Col className="col-6 d-flex justify-content-center align-items-center">
        <img className="page-logo-img" src={logo} alt="Logo" />
      </Col>
    </Row>
  )
}

// Header.propTypes = {
// siteTitle: PropTypes.string,
// }
//
// Header.defaultProps = {
// siteTitle: ``,
// }

export default Header
